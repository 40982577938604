<template>
  <div>
    <AppDialogTWSyncOptions
      v-model="showDialogSyncOptions"
      :tw-sync-options="twSyncOptions"
      :tw-projects="twProjects"
      :tw-tasklists="twTasklists"
      :tw-notebooks="twNotebooks"
      :fetching-tasklists="fetchingTasklists"
      :fetching-notebooks="fetchingNotebooks"
      :entity="entity"
      :enable-notebook-export="enableNotebookExport"
      @set-message="setMessage"
      @fetch-tasklists="fetchTasklists"
      @fetch-notebooks="fetchNotebooks"
      @initialize-tw-oauth-process="initiateTeamworkOAuth"
      @change-tw-site="changeTWSite"
      @update-tw-sync-options="updateSyncOptions"
      @close="showDialogSyncOptions = false"
    />

    <AppDialogTWAPIKey
      v-model="showDialogAPIKey"
      :is-validating="isValidatingAPIKey"
      @submit="validateAPIKey"
      @close="showDialogAPIKey = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AppDialogTWSyncOptions from '@/components/shared/dialogs/AppDialogTWSyncOptions'
import AppDialogTWAPIKey from '@/components/shared/dialogs/AppDialogTWAPIKey'

import DataManager from '@/helpers/dataManager'

export default {
  name: 'AppTeamworkSync',
  components: {
    AppDialogTWSyncOptions,
    AppDialogTWAPIKey
  },
  props: {
    collectionName: {
      type: String,
      required: true
    },
    docId: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    enableNotebookExport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      isUpdatingLinks: false,
      showDialogSyncOptions: false,
      showDialogAPIKey: false,
      isValidatingAPIKey: false,
      fetchingTasklists: false,
      fetchingNotebooks: false,
      twProjects: [],
      twTasklists: [],
      twNotebooks: []
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'loggedInUserData'])
  },
  async created () {
    // grab sync params from user account after sign in with TW
    const useAccSyncParams = this.$route.query.useAccSyncParams
    const accessToken = this.loggedInUserData.twAccessToken
    const apiEndPoint = this.loggedInUserData.twAPIEndPoint
    const domain = this.loggedInUserData.twURL
    const siteName = this.loggedInUserData.twSiteName

    if (useAccSyncParams && !this.twSyncOptions.accessToken && accessToken && apiEndPoint && domain) {
      this.saveTWAuthTokenAndURL({ accessToken, apiEndPoint, domain, siteName, isAPIKey: false })
    }

    // redirected after sync with teamwork btn click
    const code = this.$route.params.code
    if (code) this.syncFromURLCode(code)
  },
  methods: {
    ...mapActions([
      'getTeamworkAuthToken',
      'getAccountDetails',
      'getProjects',
      'getTasklists',
      'getNotebooks',
      'getUserByEmailId',
      'setMessage',
      'configAxios'
    ]),
    initiateTeamworkOAuth () {
      window.location = `https://www.teamwork.com/launchpad/login?redirect_uri=${process.env.VUE_APP_TEAMWORK_REDIRECT_URI}&state=${this.docId}&client_id=${process.env.VUE_APP_TEAMWORK_CLIENT_ID}`
    },
    async syncFromURLCode (code) {
      try {
        const { data } = await this.getTeamworkAuthToken(code)

        const accessToken = data.access_token
        const apiEndPoint = data.installation ? data.installation.apiEndPoint : ''
        const domain = data.installation ? data.installation.url : ''
        const siteName = data.installation ? data.installation.name : ''

        this.saveTWAuthTokenAndURL({
          accessToken,
          apiEndPoint,
          domain,
          siteName,
          isAPIKey: false
        })

        this.dataManager.updateLoggedInUserData({
          twAccessToken: accessToken,
          twAPIEndPoint: apiEndPoint,
          twURL: domain,
          twSiteName: siteName
        })
      } catch (error) {
        this.showDialogAPIKey = true
        this.$logError({
          error,
          context: {
            extra: {
              user: this.loggedInUser,
              code
            }
          }
        })
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    async saveTWAuthTokenAndURL (options) {
      try {
        await this.dataManager.updateObject(this.collectionName, this.docId, {
          'meta.twSyncOptions': {
            ...this.twSyncOptions,
            ...options,
            syncedBy: this.loggedInUser.email,
            syncedOn: this.$helpers.getUnixTimestamp()
          }
        })

        this.fetchSyncedOptions()
        this.showDialogSyncOptions = true

        return
      } catch (error) {
        this.setMessage({
          type: 'error',
          message: `Error saving access token: ${error.message}`
        })
      }
    },
    async fetchSyncedOptions () {
      const syncOptions = this.twSyncOptions || {}
      const accessToken = syncOptions.accessToken || ''
      const apiEndPoint = syncOptions.apiEndPoint || ''
      const isAPIKey = !!syncOptions.isAPIKey

      // To set axios auth config between 'Bearer' and Username/Password
      await this.configAxios({ token: accessToken, isAPIKey, apiEndPoint })
      await this.fetchProjects()
      await this.fetchTasklists()
      this.fetchNotebooks()
    },
    async fetchProjects () {
      try {
        this.twTasklists = []
        // fetch projects
        const { data } = await this.getProjects()
        this.twProjects = data.projects || []

        this.$emit('tw-projects', this.twProjects)
        return this.twProjects
      } catch (error) {
        this.updateSyncOptions({ ...this.twSyncOptions, accessToken: null }, true)
        this.setMessage({
          type: 'error',
          message: 'We encountered an error while fetching the details from Teamwork. Please sync again.'
        })
      }
    },
    async fetchTasklists (projectId = this.twSyncOptions?.projectId) {
      if (!this.twSyncOptions.accessToken || !projectId) return []

      try {
        this.fetchingTasklists = true
        const { data } = await this.getTasklists(projectId)
        this.twTasklists = data.tasklists || []

        this.$emit('tw-tasklists', this.twTasklists)

        return this.twTasklists
      } catch (error) {
        const notFound = error?.response.status === 404
        const user = this.twSyncOptions.syncedBy
        const { data } = await this.getUserByEmailId(user)
        const userObj = data.people[0]

        if (!notFound || !userObj.deleted) {
          this.setMessage({
            type: 'error',
            message: `Error while fetching tasklists: ${error.message}`
          })

          return
        }

        // remove selected options
        this.updateSyncOptions({
          ...this.twSyncOptions,
          accessToken: null
        }, true)

        this.setMessage({
          type: 'error',
          message: `It looks user account '${user}' no longer exists. Please sync again with Teamwork.`
        })
      } finally {
        this.fetchingTasklists = false
      }
    },
    async fetchNotebooks (projectId = this.twSyncOptions?.projectId) {
      if (!this.twSyncOptions.accessToken || !projectId || !this.enableNotebookExport) return []

      try {
        this.fetchingNotebooks = true
        const { data } = await this.getNotebooks(projectId)
        this.twNotebooks = data.project.notebooks || []
      } catch (error) {
        this.setMessage({
          type: 'error',
          message: error.message
        })
      } finally {
        this.fetchingNotebooks = false
      }
    },
    async validateAPIKey ({ apiKey, apiEndPoint }) {
      try {
        this.isValidatingAPIKey = true
        await this.configAxios({ token: apiKey, isAPIKey: true, apiEndPoint })

        await this.fetchAccountDetails()
        await this.saveTWAuthTokenAndURL({
          accessToken: apiKey,
          apiEndPoint,
          isAPIKey: true
        })

        this.showDialogAPIKey = false
      } catch (error) {
        const hasInvalidAPIKey = error && error.response && error.response.status === 401
        this.setMessage({
          type: 'error',
          message: hasInvalidAPIKey ? 'Invalid API Key' : error.message
        })
      } finally {
        this.isValidatingAPIKey = false
      }
    },
    async fetchAccountDetails () {
      const { data } = await this.getAccountDetails()
      return this.updateSyncOptions({
        ...this.twSyncOptions,
        siteName: data.account.name,
        domain: data.account.URL
      }, true)
    },
    async changeTWSite (options) {
      this.twProjects = []
      this.twTasklists = []
      this.twNotebooks = []

      await this.updateSyncOptions({
        ...options,
        syncedBy: this.loggedInUser.email,
        syncedOn: this.$helpers.getUnixTimestamp()
      }, true)
      this.fetchSyncedOptions()
    },
    async updateSyncOptions (options = {}, silentUpdate = false) {
      try {
        // make sure not clearing options
        if (Object.keys(options).length > 0) {
          options.updatedBy = this.loggedInUser.email
          options.updatedOn = this.$helpers.getUnixTimestamp()
        }

        await this.dataManager.updateObject(this.collectionName, this.docId, {
          'meta.twSyncOptions': options
        })
        if (silentUpdate) return
        this.showDialogSyncOptions = false
        // emit event
        if (options.projectId && !this.twSyncOptions.projectId && options.tasklistId && !this.twSyncOptions.tasklistId) {
          this.$emit('sync-options-added')
        } else {
          this.$emit('sync-option-updated')
        }
        // show success message
        this.setMessage({
          type: 'success',
          message: 'Updated default synchronization options'
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating synchronization options: ${error.message}` })
      }
    }
  }
}
</script>
