<template>
  <v-tooltip top max-width="300px">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="sync">
        <v-icon :class="{ 'syncing': syncing }">mdi-sync</v-icon>
      </v-btn>
    </template>
    <span>
      <template v-if="syncing">Syncing...</template>
      <template v-else>{{ text }}</template>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppSyncBtn',
  props: {
    syncing: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    sync () {
      if (this.syncing) return
      this.$emit('sync')
    }
  }
}
</script>

<style lang="scss" scoped>
.syncing {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
