<template>
  <v-dialog :value="value" persistent max-width="450px">
    <form @submit.prevent="submit">
      <v-card>
        <v-card-title>
          <span class="headline mb-2">
            Sync with
            Teamwork <AppTeamworkBranding info size="18px" icon-class="ml-n1" />
          </span>
          <span class="subtitle-2">Please select the project and tasklist you would like to sync with</span>
        </v-card-title>

        <v-alert dense type="success" class="mx-3 mb-2 text-body-2">
          Great! You can now sync your {{ entity }} with Teamwork.
          Please make sure to use the email address registered with Teamwork while adding users in order to assign {{ entity }} to the correct user.
        </v-alert>

        <v-card-text>
          <v-autocomplete
            v-model="syncOptions.projectId"
            :menu-props="{ offsetY: true }"
            :items="twProjects"
            item-value="id"
            item-text="name"
            label="Default Project"
            no-data-text="No project found"
            class="pr-1"
            outlined
            hide-details
            @change="fetchTaskListsAndNotebooks"
          />

          <v-autocomplete
            v-model="syncOptions.tasklistId"
            :menu-props="{ offsetY: true }"
            :loading="fetchingTasklists"
            :disabled="twProjects.length === 0"
            :items="twTasklists"
            item-value="id"
            item-text="name"
            label="Default Tasklist"
            no-data-text="No tasklist found"
            class="pr-1 my-5"
            outlined
            hide-details
          />

          <v-combobox
            v-if="enableNotebookExport"
            v-model="selectedNotebook"
            :search-input.sync="searchedNotebookTerm"
            :menu-props="{ offsetY: true }"
            :loading="fetchingNotebooks"
            :disabled="twProjects.length === 0"
            :items="twNotebooks"
            item-value="id"
            item-text="name"
            label="Export to notebook (optional)"
            no-data-text="No notebook found"
            class="pr-1 mt-5"
            hint="Once the Weekly Meeting is ended, we will automatically export the meeting notes into a Teamwork Notebook. Please select and existing notebook in Teamwork or automatically create a new one by typing in the name for the notebook."
            persistent-hint
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" class="ml-2 mb-2" @click="unsync">Unsync</v-btn>
          <div class="mb-2 ml-2">
            <AppTeamworkSyncSites
              top
              text="Change Teamwork site"
              text-class="text-button text-caption"
              :teamwork-sites="twSites"
              @initialize-tw-oauth-process="$emit('initialize-tw-oauth-process')"
              @save-sync-options="$emit('change-tw-site', $event)"
            />
          </div>
          <v-spacer />
          <v-btn text class="mb-2" @click="close">Cancel</v-btn>
          <v-btn
            color="success"
            class="mr-2 mb-2"
            @click="submit"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'
import AppTeamworkSyncSites from '@/components/shared/sync/AppTeamworkSyncSites'

const DEFAULT_SYNC_OPTIONS = {
  accessToken: null,
  apiEndPoint: null,
  domain: null,
  siteName: null,
  projectId: null,
  tasklistId: null,
  notebookId: null
}

export default {
  name: 'AppDialogTWSyncOptions',
  components: { AppTeamworkBranding, AppTeamworkSyncSites },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    },
    twNotebooks: {
      type: Array,
      default: () => ([])
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    fetchingTasklists: {
      type: Boolean,
      default: false
    },
    fetchingNotebooks: {
      type: Boolean,
      default: false
    },
    entity: {
      type: String,
      default: ''
    },
    enableNotebookExport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      syncOptions: DEFAULT_SYNC_OPTIONS,
      searchedNotebookTerm: ''
    }
  },
  computed: {
    ...mapGetters(['syncedTWSites']),
    twSites () {
      return this.syncedTWSites.filter(site => site.twAPIEndPoint !== this.syncOptions.apiEndPoint)
    },
    selectedNotebook: {
      get () {
        return this.getNotebookById(this.syncOptions.notebookId) || this.syncOptions.notebookId
      },
      set (value) {
        this.setNotebookId(typeof value === 'string' ? value : value.id)
      }
    }
  },
  watch: {
    twSyncOptions: {
      immediate: true,
      handler (options) {
        if (!this.enableNotebookExport) delete this.syncOptions.notebookId
        this.syncOptions = { ...DEFAULT_SYNC_OPTIONS, ...options }
      }
    }
  },
  methods: {
    submit () {
      // default project not selected
      if (!this.syncOptions.projectId) {
        return this.$emit('set-message', { type: 'error', message: 'Please select default project' })
      }
      // default tasklist not selected
      if (!this.syncOptions.tasklistId) {
        return this.$emit('set-message', { type: 'error', message: 'Please select default tasklist' })
      }

      // check if search terms is different than any notebook name
      if (this.enableNotebookExport && this.selectedNotebook) {
        const createNotebook = this.selectedNotebook.id ? this.selectedNotebook.name !== this.searchedNotebookTerm
          : this.selectedNotebook !== this.searchedNotebookTerm
        if (createNotebook) this.setNotebookId(this.searchedNotebookTerm)
      }
      this.$emit('update-tw-sync-options', this.syncOptions)
    },
    unsync () {
      this.$emit('update-tw-sync-options', {})
      this.close()
      this.syncOptions = DEFAULT_SYNC_OPTIONS
    },
    close () {
      this.$emit('close')
    },
    fetchTaskListsAndNotebooks (projectId) {
      this.$emit('fetch-tasklists', projectId)
      if (this.enableNotebookExport) this.$emit('fetch-notebooks', projectId)
    },
    getNotebookById (id = '') {
      return this.twNotebooks.slice().find(notebook => ((notebook.id / 1) === (id / 1)))
    },
    setNotebookId (id = null) {
      this.$set(this.syncOptions, 'notebookId', id)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__mask {
  background: rgb(255, 232, 177) !important;
  color: rgb(0, 0, 0) !important;
}
</style>
