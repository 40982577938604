<template>
  <v-dialog :value="value" persistent max-width="450px">
    <v-form v-model="isValid" @submit.prevent="submit">
      <v-card color="white">

        <v-card-title class="headline">
          Add API Key
        </v-card-title>

        <v-alert dense icon="mdi-alert" type="warning" class="mx-3 mb-5 text-body-2">
          We noticed that you encountered an error while trying to sync with Teamwork. You can still sync with Teamwork by manually adding the API key. Please refer to the detailed <a href="https://developer.teamwork.com/projects/apikey/key" target="_blank" class="white--text">guide</a> on obtaining your teamwork API key.
        </v-alert>

        <v-card-text>
          <v-text-field
            v-model="url"
            label="Site Address"
            placeholder="https://yoursite.teamwork.com"
            :rules="urlRules"
            outlined
            autofocus
          />
          <v-text-field
            v-model="token"
            label="API Key"
            placeholder="twp_HSsmRP8hR1WLIAEZjbn9Ajjvf"
            class="mt-3"
            :rules="keyRules"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text class="mb-2" @click="close">Cancel</v-btn>
          <v-btn
            color="success"
            class="mr-2 mb-2"
            :loading="isValidating"
            :disabled="!isValid"
            @click="submit"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDialogTWAPIKey',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isValidating: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: '',
      token: '',
      isValid: false,
      keyRules: [
        v => !!v || 'API key is required'
      ],
      urlRules: [
        v => !v ? 'Site address is required' : (this.validURL(v) || 'Invalid site address')
      ]
    }
  },
  methods: {
    validURL (str) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return str.startsWith('https://') && !!pattern.test(str)
    },
    submit () {
      this.$emit('submit', {
        apiKey: this.token,
        apiEndPoint: this.url
      })
    },
    close () {
      this.$emit('close')
      this.token = ''
    }
  }
}
</script>
